import React from 'react';
import Logo from '@assets/bcbsri.png';
import Layout from '../../components/layout';
import Segment from '../../components/segment';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import ImageText from '../../components/sections/imageText/imageText';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import TestimonialGridST from '../../components/staticSections/TestimonialGridST/TestimonialGridST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import Table from '../../components/sections/table/table';
import FaqST from '../../components/staticSections/FaqST/FaqST';
import Seo from '../../components/seo';
import CtaST from '../../components/staticSections/CtaST/CtaST';

const IndexPage = () => {
  return (
    <Layout
      lang="us"
      headerWitdh="header"
      topHeader
      text="In-network with Blue Cross & Blue Shield of Rhode Island"
      showFooter
    >
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
      <Seo title="bcbsri" language="en" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedata4
        dataSection="hero_CTA"
        alt="Blue Cross & Blue Shield of Rhode Island"
        title="Treat joint and back pain from home"
        titleClassName=""
        text="Download the Joint Academy app to your phone and get started with a personalized exercise program today."
        textClassName=""
        // calldataGoal="Calendly Viewed"
        // callhref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        // callclassname="margin-top--md btn btn--primary btn--fullwidth btn--lg margin-bottom--xs"
        // calltext="Get Started"
        // rederCalendly
        downloadButton
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/6y0wzxh"
        downloadclassname="margin-top--md btn btn--fullwidth btn--lg btn--primary"
        downloadtext="GET STARTED"
        tagHeadline="Get a text to download the app"
        smsTag
        smsTagClassName="display--md"
        dataToken=""
      />
      <ImageText
        imgSize="col--sm-3 col--md-3 col--lg-3 align--center col--4Imp"
        Filename="circle_app-confirmed.png"
        alt="Blue Cross & Blue Shield of Rhode Island"
        Position=""
        title="In-network with Blue Cross & Blue Shield of Rhode Island"
        text="Joint Academy is a covered benefit under most Blue Cross & Blue Shield Rhode Island plans. Download the Joint Academy app to confirm your coverage, or contact support for further assistance."
        download
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/6y0wzxh"
        downloadclassname="margin-top--md btn btn--lg btn--primary"
        downloadtext="GET STARTED"
      />
      <GetStartedST
        lang="us_en"
        renderDownloadButton
        ctaButtonText="Get Started"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/6y0wzxh"
      />
      <BgCardsTextST dataTheme="light" lang="us_en" />
      <SocialProofFeedST lang="us_en" />
      <TestimonialGridST lang="us_en" />
      <LicensedPtSliderST
        lang="us_en"
        downloaddataGoal="Onboarding Viewed"
        downloadhref="https://app.adjust.com/6y0wzxh"
        downloadclassname="btn btn--md btn--primary "
        downloadtext="Get Started Today"
      />
      <Table
        title="Joint Academy vs. traditional treatment"
        text="Joint Academy is a digital treatment for chronic joint pain that might be beneficial you you compared to traditional face-to-face physical therapy available at clinics."
        images={[
          {
            alt: 'Price table large',
            Filename: 'table_large_bcbsri.png',
            className: 'display--sm',
          },
          {
            alt: 'Price table small',
            Filename: 'table-small-bcbsri.png',
            className: 'hide--sm',
          },
        ]}
        ctaButtonText="GET STARTED"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="https://app.adjust.com/6y0wzxh"
        subText={[
          {
            text: '*Average estimated $30 copay for PT services for 1 billable service per month. Copay and final cost may be higher and is determined by your health plan based on your plans benefits. Deductible may apply. Contact Joint Academy or your health plan for further information regarding your coverage.',
            className: '',
          },
          {
            text: '**Average $30 copay per visit for an average of 4 visits per month.',
            className: '',
          },
        ]}
      />
      <OutcomeST lang="us_en" />
      <FaqST lang="us_en" />
      <CtaST
        lang="us_en"
        ctaDataGoal="Calendly Viewed"
        ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        ctaButtonText="Schedule Call"
      />
    </Layout>
  );
};

export default IndexPage;
